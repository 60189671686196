import url from 'url';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import {
  setAccountDetails,
  setBank,
  setBankLinks,
  setError,
  setLoading,
  setOverrides,
  setSubscriptionInfo,
  setSubscriptionTypes,
  setUpdating,
  setWalletType
} from '@app/src/actions/pricingActions';
import { setSettingsModalType } from '@app/src/actions/settingsActions';
import { setIsPaymentModalOpen, setSubscriptionModalShow } from '@app/src/actions/taxFlowActions';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import {
  PAYMENT_ERROR_MESSAGE,
  PRICING_ORIGINS,
  PRICING_ORIGIN__UPGRADE_ORIGINS
} from '@app/src/constants/pricingConstants';
import { serverUrl } from '@app/src/global/Environment';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import { chargeableAccountSelector } from '@app/src/selectors/bankSelectors';
import {
  availablePlansSelector,
  bankSelector,
  chargeableAccountsSelector,
  isFilingOnlySelector,
  originSelector,
  subscriptionPriceSelector,
  subscriptionStatusSelector,
  subscriptionTypeSelector,
  trialLengthSelector,
  walletTypeSelector
} from '@app/src/selectors/pricingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getChargeableAccount } from '@app/src/services/bankService';
import { handleExportWriteOffs } from '@app/src/services/expenseReviewExportService';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const getBankLinks =
  (options = {}) =>
  async (dispatch) => {
    dispatch(setLoading({ loading: true }));
    const res = await axios.get(`${baseUrl}api/profile/banklinks-new`, {
      params: {
        includeBalances: _.get(options, 'includeBalances') ? '1' : '0',
        includeHasAccountNumbers: 1
      }
    });

    const bankLinks = _.get(res, ['data', 'data']);

    dispatch(setBankLinks({ bankLinks: _.isEmpty(bankLinks) ? [] : bankLinks }));
    dispatch(setLoading({ loading: false }));
  };

export const getSubscriptionInfo = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/pricing/subscription-info`);

  const subscriptionInfo = _.get(res, ['data', 'data']);

  dispatch(setSubscriptionInfo({ subscriptionInfo }));
};

export const getSubscriptionTypes = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/pricing/subscription-types`);

  const subscriptionTypes = _.get(res, ['data', 'data']);

  dispatch(setSubscriptionTypes({ subscriptionTypes }));
};

const updateChargedAccountRemote =
  ({ stripeToken, account_id, paymentMethod }) =>
  async () => {
    await axios.post(`${baseUrl}api/plaid/edit-charged-acct`, { stripeToken, account_id, paymentMethod });
  };

export const createSubscription =
  ({ account_id, pricing, freeTrial, origin, planType, skipAchLink }) =>
  async () => {
    return axios.post(`${baseUrl}api/plaid/create-subscription`, {
      account_id,
      pricing,
      freeTrial,
      origin,
      planType,
      skipAchLink
    });
  };

export const deleteSubscription = () => async () => {
  await axios.post(`${baseUrl}api/plaid/delete-subscription`, {});
};

export const cancelSubscription = () => async () => {
  await axios.post(`${baseUrl}api/profile/cancel`, {});
};

export const refundSubscription = () => async () => {
  await axios.post(`${baseUrl}api/profile/refund`, {});
};

const capturePaymentException = (error) => (dispatch) => {
  dispatch(setError({ error: _.get(error, 'message') }));
  notify(_.get(error, 'message'));
  defaultCaptureException(error);
};

const generateStripeCustomer =
  ({ stripeToken, skipEvent, product, amount, origin, callback, successCallback }) =>
  async (dispatch) => {
    const res = await axios.post(`${baseUrl}api/plaid/generate-customer`, { stripeToken, product, amount });
    if (_.get(res, ['data', 'status']) === 'error') {
      trackActivity('Purchase Failed', {
        product,
        currency: 'USD',
        price: amount,
        error: _.get(res, ['data', 'data', 'error']),
        origin
      });
      callback(_.get(res, ['data', 'data', 'error']));
      return;
    }

    if (!skipEvent) {
      trackActivity('Purchase', {
        product,
        currency: 'USD',
        price: amount
      });
    }

    // only update chargeable account for new bookkeeping subscription
    const account_id = _.get(res, ['data', 'data', 'customer', 'account_id']);

    if (account_id) {
      await dispatch(updateChargedAccountRemote({ account_id }));
    }

    await dispatch(getChargeableAccount());

    if (successCallback) {
      await successCallback();
    }
  };

const generateAchStripeCustomer =
  ({ account_id, pricing, freeTrial, planType }) =>
  async (dispatch) => {
    await axios.post(`${baseUrl}api/plaid/generate-customer-ach`, {
      account_id,
      pricing,
      freeTrial,
      planType
    });
    await dispatch(getChargeableAccount());
  };

export const getAccountDetails = () => async (dispatch) => {
  // TODO remove this invalidation once account details fully migrated to rtk-query
  // This ensures rtk-query stays in sink whenever this data refresh call is made
  dispatch(baseApi.util.invalidateTags([TAGS.ACCOUNT_DETAILS]));
  const res = await axios.get(`${baseUrl}api/profile/account-details`);
  dispatch(setAccountDetails({ accountDetails: _.get(res, ['data', 'data']) }));
};

export const requireAccountDetails = () => async (dispatch, getState) => {
  const accountDetails = accountDetailsSelector(getState());
  if (_.isEmpty(accountDetails)) {
    await dispatch(getAccountDetails());
  }
};

export const getPaymentMethods =
  ({ history, stripe, elements }) =>
  async (dispatch, getState) => {
    const start = performance.now();

    try {
      dispatch(setLoading({ loading: true }));
      dispatch(setWalletType({ walletType: null }));
      dispatch(setBank({ bank: null }));

      const [paymentRequest] = await Promise.all([
        dispatch(getWallet({ history, stripe, elements })),
        dispatch(getBankLinks({ includeBalances: true })),
        dispatch(getAccountDetails()),
        dispatch(getSubscriptionInfo())
      ]);

      const origin = originSelector(getState());
      const walletType = walletTypeSelector(getState());
      const chargeableAccounts = chargeableAccountsSelector(getState());

      trackActivity('web payment modal', { origin, walletType, chargeableAccounts: chargeableAccounts.length });

      return paymentRequest;
    } catch (e) {
      dispatch(capturePaymentException(e));
      return null;
    } finally {
      dispatch(setLoading({ loading: false }));

      trackActivity('load time: payment modal', {
        loadTime: performance.now() - start
      });
    }
  };

const getWallet =
  ({ history, stripe, elements }) =>
  async (dispatch, getState) => {
    if (!stripe) {
      throw new Error('stripe not available');
    }

    if (!elements) {
      throw new Error('elements not available');
    }

    const freeTrial = trialLengthSelector(getState());
    const subscriptionType = subscriptionTypeSelector(getState());
    const price = subscriptionPriceSelector(getState(), subscriptionType);

    const paymentItems =
      freeTrial > 0
        ? [
            {
              label: 'Free Trial',
              amount: 0
            },
            {
              label: `Keeper: ${freeTrial}-day free trial, then ${price}/year`,
              amount: 0
            }
          ]
        : [
            {
              label: 'Keeper',
              // Stripe requires integer amounts. For discounted prices,
              // we show the user the float discount price. But when we make
              // the actual backend call to stripe, we do send an integer of full price,
              // and then apply whatever coupon (resulting in a matching float)
              amount: Math.floor(price) * 100
            }
          ];

    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: _.get(paymentItems, 0),
      displayItems: paymentItems,
      disableWallets: ['link', 'browserCard']
    });

    paymentRequest.on('token', (event) => {
      dispatch(saveWalletPaymentMethod({ history, event }));
    });

    const canMakePayment = await paymentRequest.canMakePayment();
    const walletType = _.get(canMakePayment, 'applePay')
      ? 'apple_pay'
      : _.get(canMakePayment, 'googlePay')
        ? 'google_pay'
        : null;

    dispatch(setWalletType({ walletType }));

    return paymentRequest;
  };

const saveWalletPaymentMethod =
  ({ history, event }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setUpdating({ updating: true }));

      const walletType = walletTypeSelector(getState());
      await dispatch(savePaymentMethod({ history, type: walletType, token: _.get(event, 'token') }));

      event.complete('success');
    } catch (e) {
      event.complete('fail');
      dispatch(capturePaymentException(e));
    } finally {
      dispatch(setUpdating({ updating: false }));
    }
  };

export const saveBankPaymentMethod =
  ({ history }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setUpdating({ updating: true }));

      const account_id = bankSelector(getState());

      await dispatch(savePaymentMethod({ history, type: 'ach', account_id }));
    } catch (e) {
      dispatch(capturePaymentException(e));
    } finally {
      dispatch(setUpdating({ updating: false }));
    }
  };

export const saveCardPaymentMethod =
  ({ history, stripe, elements }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setUpdating({ updating: true }));

      const cardNumberElement = elements.getElement('cardNumber');

      if (!cardNumberElement) {
        throw new Error(PAYMENT_ERROR_MESSAGE);
      }

      const { firstname, lastname } = userSelector(getState());

      const { token } = await stripe.createToken(cardNumberElement, {
        name: `${firstname} ${lastname}`
      });

      await dispatch(savePaymentMethod({ history, type: 'credit_card', token }));
    } catch (e) {
      dispatch(capturePaymentException(e));
    } finally {
      dispatch(setUpdating({ updating: false }));
    }
  };

const savePaymentMethod =
  ({ history, type, token, account_id }) =>
  async (dispatch, getState) => {
    const origin = originSelector(getState());

    if (PRICING_ORIGIN__UPGRADE_ORIGINS.includes(origin)) {
      if (type === 'ach') {
        await dispatch(createSubscriptionByAccount({ history, type, account_id }));
      } else {
        await dispatch(saveTokenPaymentMethod({ history, type, token }));
      }
    } else {
      await dispatch(updateChargedAccountRemote({ stripeToken: _.get(token, 'id'), account_id, paymentMethod: type }));
      await dispatch(getChargeableAccount());

      const chargeableAccount = chargeableAccountSelector(getState());
      const accountDetails = accountDetailsSelector(getState());

      trackActivity('payment method updated', {
        type,
        institution_name: chargeableAccount.institution_name,
        prior_status: accountDetails.status,
        origin
      });

      notify(
        `You’re all set! We've successfully updated your default payment method to ${chargeableAccount.displayName}.`
      );

      dispatch(setIsPaymentModalOpen(false));
    }
  };

const saveTokenPaymentMethod =
  ({ history, type, token }) =>
  async (dispatch, getState) => {
    const subscriptionType = subscriptionTypeSelector(getState());
    const price = subscriptionPriceSelector(getState(), subscriptionType);

    if (!_.get(token, 'id')) {
      trackActivity('tax file: add payment method fail', { price });
      throw new Error('Please enter your credit card details');
    }

    const origin = originSelector(getState());

    await dispatch(
      generateStripeCustomer({
        stripeToken: token.id,
        origin,
        skipEvent: true,
        product: 'bookkeeping',
        amount: price,
        callback: (error) => {
          throw new Error(error || PAYMENT_ERROR_MESSAGE);
        }
      })
    );

    const account_id = _.get(getState(), ['bank', 'chargeableAccount', 'account_id']);

    if (!account_id) {
      throw new Error(PAYMENT_ERROR_MESSAGE);
    }

    await dispatch(createSubscriptionByAccount({ history, type, account_id }));

    dispatch(setIsPaymentModalOpen(false));
  };

const createSubscriptionByAccount =
  ({ history, type, account_id }) =>
  async (dispatch, getState) => {
    const origin = originSelector(getState());

    const planType = subscriptionTypeSelector(getState());
    const freeTrial = trialLengthSelector(getState());
    const accountStatus = subscriptionStatusSelector(getState());

    const price = subscriptionPriceSelector(getState(), planType);

    if (type === 'ach' && origin !== 'edit_plan') {
      await dispatch(generateAchStripeCustomer({ account_id, planType }));
    }

    const res = await dispatch(createSubscription({ origin, planType }));

    if (_.get(res, ['data', 'status']) === 'error') {
      const errorMsg = _.get(res, ['data', 'data', 'error']);
      trackActivity('Purchase Failed', {
        product: 'bookkeeping',
        currency: 'USD',
        price,
        error: errorMsg,
        origin
      });
      throw new Error(errorMsg || PAYMENT_ERROR_MESSAGE);
    }

    trackActivity('Subscribe', {
      pricingMethod: type,
      currency: 'USD',
      price,
      type: planType,
      origin,
      freeTrial: freeTrial
    });

    const chargeableAccounts = chargeableAccountsSelector(getState());
    const chargeableAccount = type === 'ach' ? chargeableAccounts.find((c) => c.account_id === account_id) : null;

    trackActivity('payment method added', { type, institution_name: _.get(chargeableAccount, 'institution_name') });

    dispatch(setIsPaymentModalOpen(false));

    await Promise.all([dispatch(getAccountDetails()), dispatch(getSubscriptionInfo())]);

    const updatedPlanType = subscriptionTypeSelector(getState());

    if (origin === PRICING_ORIGINS.EXPENSE_REVIEW) {
      history.push(
        url.format({
          pathname: '/expenses'
        })
      );

      if (accountStatus === 'pre free trial') {
        notify(
          `Success! You’re all set. Your ${planType === 'premium' ? 'Keeper Premium' : 'Keeper'} free trial ends on ${moment()
            .add(7, 'days')
            .format('MM/DD/YYYY')}. After you'll be charged ${currencyWith0DecimalPlaces(price)}/year.`
        );
      } else {
        notify(
          `Success! You’re all set. You have re-subscribed to Keeper for ${currencyWith0DecimalPlaces(price)}/year`
        );
      }

      await dispatch(getAccountDetails());
      dispatch(setExpenseReviewModalType(''));
    } else if (origin === PRICING_ORIGINS.EXPORT) {
      await dispatch(handleExportWriteOffs(true));
      notify('You have successfully subscribed to Keeper!');
    } else if (origin === PRICING_ORIGINS.EDIT_PLAN) {
      const confirmationCopy =
        accountStatus === 'free trial' ? '' : ' We applied the remaining credit from your existing plan.';

      dispatch(setSettingsModalType(''));

      notify(`Your Keeper plan has been updated from ${planType} to ${updatedPlanType}.` + confirmationCopy);
    } else if (
      origin === PRICING_ORIGINS.TAX_FILING_HARD_PAYWALL ||
      origin === PRICING_ORIGINS.TAX_FILING_PREMIUM_PAYWALL ||
      origin === PRICING_ORIGINS.TAX_FILING_TRIAL_PAYWALL
    ) {
      dispatch(setSubscriptionModalShow(false));
      await dispatch(getAccountDetails());
    } else {
      history.push(
        url.format({
          pathname: '/onboarding/app-download',
          query: { origin: 'web-paywall' }
        })
      );
      notify('You have successfully subscribed to Keeper!');
      await dispatch(getAccountDetails());
    }
  };

export const setSubscriptionOverrides = (origin, data) => async (dispatch, getState) => {
  if (origin === PRICING_ORIGINS.SETTINGS) {
    dispatch(
      setOverrides({
        pricingMethod: {
          title: 'Update payment method',
          CTA: {
            primary: {
              text: 'Update'
            }
          }
        }
      })
    );
  } else if (origin === PRICING_ORIGINS.EXPENSE_REVIEW) {
    if (isFilingOnlySelector(getState())) {
      dispatch(
        setOverrides({
          subscriptionBenefits: {
            title: 'Subscribe to access deductions',
            CTA: {
              primary: {
                plans: _.without(availablePlansSelector(getState()), 'filing only')
              },
              tertiary: null
            },
            ratings: false,
            body: { image: 'features' }
          }
        })
      );
    }
  }

  if (data) {
    dispatch(setOverrides(data));
  }
};

import React from 'react';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CompanyInfo from '@app/src/Components/Common/CompanyInfo/CompanyInfo';
import TaxHelp from '@app/src/assets/tax-help.svg?react';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { initialLoadSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingGetStarted = () => {
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const initialLoad = useSelector(initialLoadSelector);

  const { location, push } = useHistory();
  const { pathname } = location;

  const handleGetStarted = () => {
    dispatch(onContinue({ pathname, push }));
  };

  return (
    <Stack spacing={2}>
      <Fade in={!isMobile && !initialLoad} direction='right' unmountOnExit mountOnEnter timeout={100}>
        <div>
          <TaxHelp />
        </div>
      </Fade>
      {initialLoad ? null : (
        <>
          <Button
            variant='contained'
            color='secondary'
            style={{ marginBottom: isMobile ? '10%' : 0 }}
            onClick={handleGetStarted}
          >
            Get Started
          </Button>
          <CompanyInfo />
        </>
      )}
    </Stack>
  );
};

export default OnboardingGetStarted;

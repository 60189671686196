import React from 'react';

/**
 * @typedef TaxProfileFormSectionProps
 * @property {string} header
 * @property {React.ReactNode} children
 * @property {React.CSSProperties} [headerStyle]
 */

/** @type {React.FC<TaxProfileFormSectionProps>} */
const TaxProfileFormSection = ({ header, children, headerStyle, id }) => {
  return (
    <section id={id}>
      <h3 style={{ fontWeight: '500', fontSize: 20, marginTop: 24, marginBottom: 16, ...headerStyle }}>{header}</h3>
      <div
        style={{
          display: 'flex',
          gap: 8,
          flexDirection: 'column'
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default TaxProfileFormSection;

import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SubscriptionTypeElement from '@app/src/Components/Subscription/Common/SubscriptionTypeElement';
import { setOrigin, setPlansShown, setSubscriptionType } from '@app/src/actions/pricingActions';
import { setSettingsModalType } from '@app/src/actions/settingsActions';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import {
  availablePlansSelector,
  currentSubscriptionTypeSelector,
  membershipTextSelector,
  subscriptionPriceSelector,
  subscriptionStatusSelector,
  willCancelSelector
} from '@app/src/selectors/pricingSelectors';
import { isCancelingSubscriptionSelector } from '@app/src/selectors/settingsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { colorDeactivatedLight, colorError, colorSecondary } from '@app/src/theme';
import '@app/src/Components/Settings/SettingsSubscription.scss';

const SettingsSubscription = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const status = useSelector(subscriptionStatusSelector);
  const subscriptionType = useSelector(currentSubscriptionTypeSelector);
  const currentSubscriptionType = useSelector(currentSubscriptionTypeSelector);
  const price = useSelector((state) => subscriptionPriceSelector(state, subscriptionType));
  const willCancel = useSelector(willCancelSelector);
  const membershipStatusDescription = useSelector(membershipTextSelector);
  const isCancelingSubscription = useSelector(isCancelingSubscriptionSelector);
  const plansShown = useSelector(availablePlansSelector);

  useEffect(() => {
    dispatch(setSubscriptionType(subscriptionType || currentSubscriptionType));

    return () => dispatch(setSettingsModalType(''));
  }, [dispatch, subscriptionType, currentSubscriptionType]);

  useEffect(() => {
    trackActivity('navigation: subscription');
  }, []);

  const handleEditSubscriptionClick = () => {
    dispatch(setOrigin({ origin: PRICING_ORIGINS.EDIT_PLAN }));
    dispatch(setPlansShown({ plansShown }));
    dispatch(setSettingsModalType('edit_plan'));
  };

  const onEditPaymentMethodClick = () => {
    dispatch(setOrigin({ origin: PRICING_ORIGINS.EDIT_PAYMENT }));
    dispatch(setSettingsModalType('edit_payment'));
  };

  const onCancelClick = () => {
    dispatch(setSettingsModalType('churn-initial'));
  };

  const onDeleteAccountClick = () => {
    dispatch(setSettingsModalType('delete-account'));
  };

  const onStartFreeTrialClick = () => {
    push('/expenses');
  };

  return (
    <>
      <div className='question-title'>Manage subscription</div>
      <Divider />
      <div className='question-body'>{membershipStatusDescription}</div>
      <Stack spacing={1} style={{ margin: '16px auto' }}>
        {status === 'pre free trial' && (
          <Button fullWidth variant='contained' color='secondary' onClick={onStartFreeTrialClick}>
            Start free trial
          </Button>
        )}
        {!!subscriptionType && (
          <>
            <SubscriptionTypeElement
              type={subscriptionType}
              price={price}
              focused
              style={{
                backgroundColor: colorDeactivatedLight,
                cursor: 'auto',
                marginBottom: '8px'
              }}
              onClick={() => {}}
              disableRipple
              hideBubble
            />
            {willCancel && status !== 'active' ? (
              <Button
                fullWidth
                variant='contained'
                color='secondary'
                style={{ borderColor: colorSecondary }}
                onClick={handleEditSubscriptionClick}
              >
                <span>Restart subscription</span>
              </Button>
            ) : (
              <>
                <Button
                  fullWidth
                  variant='outlined'
                  color='secondary'
                  style={{ borderColor: colorSecondary }}
                  onClick={handleEditSubscriptionClick}
                >
                  <span>Edit subscription</span>
                </Button>
                <Button
                  fullWidth
                  variant='outlined'
                  color='secondary'
                  style={{ borderColor: colorSecondary }}
                  onClick={onEditPaymentMethodClick}
                >
                  <span>Edit payment method</span>
                </Button>
              </>
            )}
            {!willCancel && (
              <Button fullWidth disabled={isCancelingSubscription} onClick={onCancelClick}>
                <div>{status === 'free trial' ? 'Cancel free trial' : 'Cancel subscription renewal'}</div>
              </Button>
            )}
          </>
        )}
        <Button fullWidth disabled={isCancelingSubscription} onClick={onDeleteAccountClick} sx={{ color: colorError }}>
          <div className='text-base' style={{ color: colorError }}>
            Delete account and all data
          </div>
        </Button>
      </Stack>
    </>
  );
};

export default SettingsSubscription;

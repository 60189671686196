import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ConfirmDisconnectModal from '@app/src/Components/Bank/ConfirmDisconnectModal';
import LinkedAccount from '@app/src/Components/Bank/LinkedAccount/LinkedAccount';
import BankLink from '@app/src/Components/BankLink/BankLink';
import ExpenseUploadBanner from '@app/src/Components/Common/ExpenseUpload/ExpenseUploadBanner';
import AddIcon from '@app/src/assets/add.svg?react';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import CreditCardIcon from '@app/src/assets/card.svg?react';
import { EXPENSE_UPLOAD_BANK_LIST_TITLE } from '@app/src/constants/constants';
import { viewableAccountsSelector } from '@app/src/selectors/bankSelectors';
import { isInConfirmAmountsSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { changeAccountType, getBankSummaryV2, removeSubAccount } from '@app/src/services/bankService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import getImageUrl from '@app/src/utils/getImageUrl';
import '@app/src/Components/Bank/BankList/BankList.scss';

const BankList = ({
  bankList,
  getBankSummaryV2,
  changeAccountType,
  removeSubAccount,
  setSelfUnlockModalOpen,
  isInConfirmAmounts,
  origin,
  type = 'bank',
  ...props
}) => {
  const [subaccount, setSubaccount] = useState(null);

  useEffect(() => {
    trackActivity('navigation: add accounts');

    getBankSummaryV2();
  }, [getBankSummaryV2]);

  const handleRemoveAccount = (account) => {
    removeSubAccount(account);
    setSubaccount(null);
  };

  return (
    <div className='bank-list'>
      <div>
        <BankLink icon={<BankIcon />} className='bank-link-button' origin={origin} type={type}>
          <span>Checking accounts</span>
          <AddIcon />
        </BankLink>
        <BankLink icon={<CreditCardIcon />} className='bank-link-button' origin={origin} type={type}>
          <span>Credit card</span>
          <AddIcon />
        </BankLink>
      </div>
      {origin === 'settings' && (
        <ExpenseUploadBanner title={EXPENSE_UPLOAD_BANK_LIST_TITLE} origin={'linked-accounts'} />
      )}
      <div className={bankList.length > 0 ? 'bank-details' : ''}>
        {bankList.map((account, idx) => (
          <LinkedAccount
            key={idx}
            {...account}
            {...props}
            setSubaccount={setSubaccount}
            setSelfUnlockModalOpen={setSelfUnlockModalOpen}
            changeAccountType={changeAccountType}
            isInConfirmAmounts={isInConfirmAmounts}
            origin={origin}
            type={type}
          />
        ))}
      </div>
      {bankList?.length > 0 && (
        <div className='bank-link-security-footer'>
          <span className='bank-link-security-text'>Bank-level security, powered by </span>
          <img className='plaid-logo' src={getImageUrl('plaid-link.png')} alt='Connect your bank' height={27} />
        </div>
      )}
      <ConfirmDisconnectModal
        onCancel={() => setSubaccount(null)}
        onConfirm={handleRemoveAccount}
        subaccount={subaccount}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  bankList: viewableAccountsSelector(state),
  expense: state.expense,
  isInConfirmAmounts: isInConfirmAmountsSelector(state)
});

const mapDispatchToProps = {
  getBankSummaryV2,
  changeAccountType,
  removeSubAccount,
  setSelfUnlockModalOpen
};

const ConnectedBankList = connect(mapStateToProps, mapDispatchToProps)(BankList);

export default ConnectedBankList;

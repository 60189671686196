import React, { useEffect } from 'react';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { ArrowLeft2 } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionTypeElement from '@app/src/Components/Subscription/Common/SubscriptionTypeElement';
import { setSubscriptionType } from '@app/src/actions/pricingActions';
import { SUBSCRIPTION_MODAL_TYPES, SUBSCRIPTION_PLANS } from '@app/src/constants/constants';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import {
  currentSubscriptionTypeSelector,
  originSelector,
  plansShownSelector,
  subscriptionDefaultsSelector,
  subscriptionPriceSelector,
  subscriptionStatusSelector,
  subscriptionTypeSelector
} from '@app/src/selectors/pricingSelectors';
import { settingsLoadingSelector } from '@app/src/selectors/settingsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { updateSubscriptionType } from '@app/src/services/settingsService';
import '@app/src/Components/Subscription/SubscriptionType/SubscriptionType.scss';

const SubscriptionType = ({ onNext, showBackButton = () => false }) => {
  const dispatch = useDispatch();

  const loading = useSelector(settingsLoadingSelector);
  const origin = useSelector(originSelector);
  const plansShown = useSelector(plansShownSelector);
  const subscriptionType = useSelector(subscriptionTypeSelector);
  const subscriptionInfo = useSelector((state) =>
    subscriptionDefaultsSelector(state, SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_TYPE)
  );
  const currentSubscriptionType = useSelector(currentSubscriptionTypeSelector);
  const status = useSelector(subscriptionStatusSelector);

  const currentSubscriptionPrice = useSelector((state) => subscriptionPriceSelector(state, currentSubscriptionType));
  const subscriptionPrice = useSelector((state) => subscriptionPriceSelector(state, subscriptionType));

  const isEditPlan = origin === PRICING_ORIGINS.EDIT_PLAN;

  const title = _.get(subscriptionInfo, 'title', 'Pick a plan');
  const subtitle = _.get(subscriptionInfo, 'subtitle', '');
  const primaryCTA = _.get(subscriptionInfo, ['CTA', 'primary', 'text'], 'Continue');
  const refundCopy = _.get(subscriptionInfo, 'refundCopy', '');
  const defaultSelected = _.get(subscriptionInfo, 'defaultSelected');

  useEffect(() => {
    trackActivity('subscription type modal', { origin });

    if (_.isNil(subscriptionType)) {
      dispatch(setSubscriptionType(defaultSelected || currentSubscriptionType));
    }
  }, [origin, currentSubscriptionType, defaultSelected, dispatch, subscriptionType]);

  const handleContinue = () => {
    if (isEditPlan) {
      if (['trialing', 'active'].includes(status)) {
        if (currentSubscriptionPrice > subscriptionPrice) {
          dispatch(updateSubscriptionType());
          return;
        }
      }
    }

    onNext('pricingMethod');
  };

  const handleSelect = (type) => {
    dispatch(setSubscriptionType(type));
  };

  const plansShownSortFunction = (a, b) => {
    const priority = {
      [SUBSCRIPTION_PLANS.MONTHLY]: 1,
      [SUBSCRIPTION_PLANS.PREMIUM]: 2,
      [SUBSCRIPTION_PLANS.ANNUAL]: 3,
      [SUBSCRIPTION_PLANS.FILING_ONLY]: 4
    };
    return priority[b] - priority[a];
  };

  return (
    <>
      {showBackButton(SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_TYPE) && (
        <IconButton
          onClick={() => onNext('subscriptionBenefits')}
          style={{ position: 'absolute', left: 0, top: 4, margin: 8 }}
        >
          <ArrowLeft2 />
        </IconButton>
      )}
      <DialogTitle variant='paywall'>{title}</DialogTitle>
      {subtitle && <DialogContentText className='center-text'>{subtitle}</DialogContentText>}
      <DialogContent>
        <Stack spacing={2} direction='column' style={{ justifySelf: 'center', paddingTop: '24px' }}>
          {plansShown.sort(plansShownSortFunction).map((type) => (
            <SubscriptionTypeElement
              key={type}
              type={type}
              onClick={handleSelect}
              focused={subscriptionType === type}
              selected={subscriptionType === type}
              style={{ width: '343px' }}
            />
          ))}
        </Stack>
      </DialogContent>
      <Stack spacing={1} direction='column'>
        <Divider />
        <LoadingButton
          variant='contained'
          color='secondary'
          loading={loading}
          fullWidth
          onClick={handleContinue}
          disabled={!subscriptionType}
          loadingIndicator='Updating subscription…'
        >
          {primaryCTA}
        </LoadingButton>
        {refundCopy && <DialogContentText>{refundCopy}</DialogContentText>}
      </Stack>
    </>
  );
};

export default SubscriptionType;
